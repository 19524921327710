import { useStaticQuery, graphql } from 'gatsby';

const useRegionQuery = () => {
    const { allRegion } = useStaticQuery(graphql`
        {
            allRegion {
                nodes {
                    name
                    databaseId
                    id
                    locations {
                        latitude
                        longitude
                    }
                }
            }
        }
    `);

    return allRegion;
};

export default useRegionQuery;
