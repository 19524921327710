import React, { useEffect } from "react";
import { Link } from "gatsby";
import { getClosestLocation } from "../../../helpers/user";
import { COURSE_REFRESHER_ALL_INSTITUTION_VALUE } from "../../../constants/constants";

const CourseRefresherFilter = (props) => {
    const {
        refreshers,
        regions,
        selectedRegion,
        handleRegionSearch,
        selectedInstitution,
        handleInstitutionSearch,
        userData,
        logAnalyticsEvent,
    } = props;

    const refresherRegions = regions.nodes.filter((item) => refreshers.nodes.map((obj: {
        region: string
    }) => obj.region)
        .includes(item.name));
    const availableInstitutions = refreshers.nodes.filter((item) => item.region.includes(selectedRegion))
        .map((obj) => obj.institution).filter((value, index, self) => self.indexOf(value) === index);

    useEffect(() => {
        if (selectedRegion && selectedRegion !== "Region") return;
        if (!refresherRegions.length) return;

        const locations = [];
        refresherRegions.map((item) => locations.push(...item.locations));
        const closestLocation = getClosestLocation(locations, userData);

        const region = refresherRegions.find((item) => item.locations.find((l) => l.latitude === closestLocation.latitude && l.longitude === closestLocation.longitude));

        const event = {
            target: {
                value: region?.name,
            },
        };

        handleRegionSearch(event);
    }, []);

    return (
        <>
            {selectedRegion === "Region" ? (
                <select disabled size={1}
                        className="mx-[11px] my-[5px] w-full rounded-[6px] border-2 border-gray py-[9px] px-[11px] font-robotoCondensed text-navPrimary text-gray sm:w-[calc(100%/3)]">
                    <option selected>Region</option>
                </select>
            ) : (
                <select
                    value={selectedRegion}
                    onChange={handleRegionSearch}
                    size={1}
                    className="mx-[11px] my-[5px] w-full rounded-[6px] border-2 border-blue py-[9px] px-[11px] pr-[35px] font-robotoCondensed text-navPrimary text-blue sm:w-[calc(100%/3)]"
                >
                    {/*<option selected>Region</option>*/}
                    {selectedRegion === "Region" && <option selected>Region</option>}

                    {refresherRegions?.map((region, index) => {
                        return (
                            <option className="font-robotoCondensed text-blue" key={index} value={region.name}>
                                {region.name}
                            </option>
                        );
                    })}
                </select>
            )}

            {selectedRegion === "Region" ? (
                <select disabled size={1}
                        className="mx-[11px] my-[5px] w-full rounded-[6px] border-2 border-gray py-[9px] px-[11px] font-robotoCondensed text-navPrimary text-gray sm:w-[calc(100%/3)]">
                    <option selected>Institution</option>
                </select>
            ) : (
                <select
                    size={1}
                    value={selectedInstitution}
                    onChange={handleInstitutionSearch}
                    className="mx-[11px] my-[5px] w-full rounded-[6px] border-2 border-blue py-[9px] pr-[35px] pl-[11px] font-robotoCondensed text-blue sm:w-[calc(100%/3)]"
                >
                    {selectedInstitution === "Institution" && <option selected>Institution</option>}
                    <option
                        value={COURSE_REFRESHER_ALL_INSTITUTION_VALUE}>{COURSE_REFRESHER_ALL_INSTITUTION_VALUE}</option>

                    {availableInstitutions.map((institution, index) => {
                        return (
                            <option className="!font-robotoCondensed text-blue" key={index} value={institution}>
                                {institution}
                            </option>
                        );
                    })}
                </select>
            )}
            {selectedRegion != "Region" && selectedInstitution != "Institution" ? (
                <Link
                    to={`CourseRefresher?region=${selectedRegion}&inst=${selectedInstitution}`}
                    onClick={() => logAnalyticsEvent("quick_finder", "quickfinder_values", `Refresher Region-${selectedRegion}-Institution-${selectedInstitution}`)}
                    className=" radius-[6px] mx-[11px] my-[5px] whitespace-nowrap rounded-[6px] border-2 border-blue px-[26px] py-[11.5px] font-robotoCondensed text-navPrimary uppercase text-blue "
                >
                    Find my Seat!
                </Link>
            ) : (
                <button
                    className="radius-[6px] mx-[11px] my-[5px] cursor-default whitespace-nowrap rounded-[6px] border-2 border-gray px-[26px] py-[11.5px] font-robotoCondensed text-navPrimary uppercase text-gray ">
                    Find my Seat!
                </button>
            )}
        </>
    );
};

export default CourseRefresherFilter;
