import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Edges from "../components/Edges";

export default function LogosBlock(props) {
    const { uri } = props;

    const data = useStaticQuery(graphql`
        {
            allFile(filter: { relativeDirectory: { eq: "logos" } }) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], width: 302, height: 143, quality: 95)
                        }
                        name
                    }
                }
            }
            allInstitutionsMetadataJson {
                nodes {
                    website
                    jsonId
                    name
                    international {
                        internationalUrl
                    }
                }
            }
        }
    `);
    const logos = data.allFile.edges.filter((file) => file.node.name !== "single" && file.node.name !== "financial");

    logos.forEach((item) => {
        let institution = data.allInstitutionsMetadataJson.nodes.find((inst) => inst.jsonId.toString() === item.node.name);
        item.node.institutionName = institution.name;
    });

    logos.sort((a, b) => (a.node.institutionName < b.node.institutionName ? -1 : 1));

    return (
        <div className="z-1 relative bg-white">
            <Edges size={uri === "/international" ? "lg" : "md"}>
                <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-16">
                    {props.header && <h2 className="mb-[70px] mt-[20px] text-center tracking-wider text-darkGray">{props.header}</h2>}
                    <div className="mt-6 mb-[50px] grid grid-cols-2 gap-0.5 md:grid-cols-4 lg:mt-8">
                        {logos.map((file, index) => {
                            const image = file && getImage(file.node.childImageSharp);
                            const fileName = parseInt(file.node.name);
                            const websiteUrl = data.allInstitutionsMetadataJson.nodes.find((institution) => institution.jsonId === fileName);

                            return (
                                <a
                                    href={uri === "/international" ? websiteUrl.international.internationalUrl : websiteUrl.website}
                                    key={index}
                                    target="_blank"
                                    className="bg-gray-50 col-span-1 flex justify-center bg-logoBG shadow-logoShadow"
                                >
                                    <GatsbyImage image={image} alt={file.node.institutionName} />
                                </a>
                            );
                        })}
                    </div>
                </div>
            </Edges>
        </div>
    );
}
