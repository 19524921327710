import React from 'react';
import Slider from 'react-slick';
import { StaticImage } from 'gatsby-plugin-image';
import '../styles/slick/slick.css';
import '../styles/slick/slick-theme.css';

const CardSlider = (props) => {
    const sliderSettings = {
        arrows: false,
        dots: false,
        fade: true,
        centerMode: false,
        infinite: true,
        touchMove: false,
        autoplaySpeed: 5000,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
        focusOnSelect: true,
        initialSlide: 0,
    };

    return (
        <div className="absolute z-0 h-[100vh] w-full">
            <Slider {...sliderSettings}>
                <div className="w-full ">
                    <StaticImage className="h-[200vh] w-full p-[0] sm:h-full" src={'../images/hero/1.jpg'} alt="" />
                </div>
                <div>
                    <StaticImage className="h-[200vh] w-full p-[0] sm:h-full" src={'../images/hero/2.jpg'} alt="" />
                </div>
                <div>
                    <StaticImage className="h-[200vh] w-full p-[0] sm:h-full" src={'../images/hero/3.jpg'} alt="" />
                </div>
                <div>
                    <StaticImage className="h-[200vh] w-full p-[0] sm:h-full" src={'../images/hero/4.jpg'} alt="" />
                </div>
            </Slider>
        </div>
    );
};

export default CardSlider;
