import React from 'react';
import { Link } from 'gatsby';

const QuickFinderFilter = (props) => {
    const { selectedProgram, handleProgramSearch, programs, selectedLevel, handleLevelsSearch, checkExistingLevel, setExistingLevel, logAnalyticsEvent } = props;

    const filteredResults = selectedLevel;

    return (
        <>
            {selectedProgram === 'Program' ? (
                <select
                    onChange={handleProgramSearch}
                    value={selectedProgram}
                    size={1}
                    className="mx-[11px] my-[5px] w-full rounded-[6px] border-2 border-blue py-[9px] pl-[11px] pr-[35px]  font-robotoCondensed text-blue sm:w-[calc(100%/3)]"
                >
                    <option selected>Program</option>
                    {programs.nodes.map((program, programIndex) => {
                        return (
                            program.hasApprenticeSchedule && (
                                <option className="text-blue font-robotoCondensed" key={programIndex} value={programIndex}>
                                    {program.name}
                                </option>
                            )
                        );
                    })}
                </select>
            ) : (
                <select
                    onChange={handleProgramSearch}
                    value={selectedProgram}
                    size={1}
                    className="mx-[11px] my-[5px] w-full rounded-[6px] border-2 border-blue py-[9px] pl-[11px] pr-[35px]  font-robotoCondensed text-blue sm:w-[calc(100%/3)]"
                >
                    {programs.nodes.map((program, programIndex) => {
                        return (
                            program.hasApprenticeSchedule && (
                                <option className="text-blue font-robotoCondensed" key={programIndex} value={programIndex}>
                                    {program.name}
                                </option>
                            )
                        );
                    })}
                </select>
            )}

            {selectedProgram === 'Program' ? (
                <select disabled size={1} className="mx-[11px] my-[5px] w-full rounded-[6px] border-2 border-gray py-[9px] pl-[11px] pr-[35px]  font-robotoCondensed text-gray sm:w-[calc(100%/3)]">
                    <option selected>Levels</option>
                </select>
            ) : (
                <select
                    onChange={handleLevelsSearch}
                    value={selectedLevel}
                    size={1}
                    className="mx-[11px] my-[5px] w-full rounded-[6px] border-2 border-blue py-[9px] pl-[11px] pr-[35px]  font-robotoCondensed text-blue sm:w-[calc(100%/3)]"
                >
                    {selectedLevel === 'Levels' && <option selected>Levels</option>}
                    {programs?.nodes[selectedProgram]?.schedules.map((levels, index) => {
                        !checkExistingLevel.includes(levels.apprenticeLevel) && setExistingLevel([...checkExistingLevel, levels.apprenticeLevel].sort());

                        return checkExistingLevel[index] == null ? null : (
                            <option className="text-blue font-robotoCondensed" key={index} value={checkExistingLevel[index]}>
                                {checkExistingLevel[index]}
                            </option>
                        );
                    })}
                </select>
            )}
            {selectedProgram != 'Program' && selectedLevel != 'Levels' ? (
                <Link
                    to={programs.nodes[selectedProgram]?.uri}
                    state={{ filteredResults, selectedLevel, isQuickFinderSearch: true }}
                    onClick={() => logAnalyticsEvent('quick_finder', 'quickfinder_values', `${programs.nodes[selectedProgram]?.name}-Level-${selectedLevel}`)}
                    className=" radius-[6px] mx-[11px] my-[5px] whitespace-nowrap rounded-[6px] border-2 border-blue px-[26px] py-[11.5px] font-robotoCondensed text-navPrimary uppercase text-blue "
                >
                    Find my Seat!
                </Link>
            ) : (
                <button className="radius-[6px] mx-[11px] my-[5px] cursor-default whitespace-nowrap rounded-[6px] border-2 border-gray px-[26px] py-[11.5px] font-robotoCondensed text-navPrimary uppercase text-gray ">
                    Find my Seat!
                </button>
            )}
        </>
    );
};

export default QuickFinderFilter;
