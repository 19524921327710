import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/gatsby";
import { graphql, Link } from "gatsby";

import useProgramQuery from "../../hooks/useProgramQuery";
import useRegionQuery from "../../hooks/useRegionQuery";
import useProgramTypeQuery from "../../hooks/useProgramTypeQuery";
import useInstitutionQuery from "../../hooks/useInstitutionQuery";
import UseRefresherQuery from "../../hooks/useRefresherQuery";

import ProgramFilter from "./filters/ProgramFilter";
import InstitutionFilter from "./filters/InstitutionFilter";
import QuickFinderFilter from "./filters/QuickFinderFilter";
import { DEFAULT_REGION, getUserLatLong } from "../../helpers/user";
import announcementsData from "../../../content/announcements.json";
import CourseRefresherFilter from "./filters/CourseRefresherFilter";

const FinderFilter = ({ isSelected, setIsSelected }) => {
    const programs = useProgramQuery();
    const regions = useRegionQuery();
    const allProgramTypes = useProgramTypeQuery();
    const institutions = useInstitutionQuery();
    const refreshers = UseRefresherQuery();

    const [selectedProgram, setSelectedProgram] = useState("Program");
    const [selectedRegion, setSelectedRegion] = useState("");
    const [selectedProgramType, setSelectedProgramType] = useState("ProgramType");
    const [selectedInstitution, setSelectedInstitution] = useState("Institution");
    const [selectedLevel, setSelectedLevel] = useState("Levels");
    const [checkExistingLevel, setExistingLevel] = useState([]);
    const [userData, setUserData] = useState(DEFAULT_REGION);

    //reset the program every time user selects a new tab
    useEffect(() => {
        if (isSelected) {
            setSelectedProgram("Program");
        }
    }, [isSelected]);

    const handleProgramSearch = (e) => {
        setExistingLevel([]);
        if (e.target.value) {
            setSelectedProgram(e.target.value);
            setSelectedProgramType("ProgramType");
            setSelectedRegion("Region");
            setSelectedLevel("Levels");
        } else {
            setSelectedProgram(null);
        }
    };

    const handleLevelsSearch = (e) => {
        if (e.target.value) {
            setSelectedLevel(e.target.value);
        } else {
            setSelectedLevel(null);
        }
    };
    const handleInstitutionSearch = (e) => {
        if (e.target.value) {
            setSelectedInstitution(e.target.value);
            setSelectedProgram("Program");
        } else {
            setSelectedInstitution(null);
        }
    };

    const handleProgramTypeSearch = (e) => {
        if (e.target.value) {
            setSelectedProgramType(e.target.value);
        } else {
            setSelectedProgramType(null);
        }
    };

    const handleRegionSearch = (e) => {
        if (e.target.value) {
            setSelectedRegion(e.target.value);
        } else {
            setSelectedRegion(null);
        }
    };

    const logAnalyticsEvent = (dimension, parameter, selectionValues) => {
        if (typeof window !== "undefined") {
            //custom gtag event only works on production and errors in develop, so bypass develop errors with try/catch
            try {
                window.gtag("event", dimension, {
                    [`${parameter}`]: selectionValues,
                });
            } catch (e) {
            }
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userData = await getUserLatLong();
                if (userData) setUserData(userData);
            } catch (error) {
                Sentry.captureException(error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <div className="w-full rounded-[6px] bg-white py-[11px] md:w-[962px]">
                <div className="flex flex-row flex-wrap px-[15px] md:flex-nowrap">
                    {isSelected === 0 && (
                        <ProgramFilter
                            selectedProgram={selectedProgram}
                            handleProgramSearch={handleProgramSearch}
                            selectedProgramType={selectedProgramType}
                            handleProgramTypeSearch={handleProgramTypeSearch}
                            selectedRegion={selectedRegion}
                            handleRegionSearch={handleRegionSearch}
                            regions={regions}
                            programs={programs}
                            allProgramTypes={allProgramTypes}
                            userData={userData}
                            logAnalyticsEvent={logAnalyticsEvent}
                        />
                    )}
                    {isSelected === 1 && (
                        <InstitutionFilter
                            selectedInstitution={selectedInstitution}
                            handleInstitutionSearch={handleInstitutionSearch}
                            institutions={institutions}
                            handleProgramSearch={handleProgramSearch}
                            selectedProgram={selectedProgram}
                            programs={programs}
                            selectedProgramType={selectedProgramType}
                            handleProgramTypeSearch={handleProgramTypeSearch}
                            allProgramTypes={allProgramTypes}
                            logAnalyticsEvent={logAnalyticsEvent}
                        />
                    )}
                    {isSelected === 2 && (
                        <CourseRefresherFilter
                            selectedInstitution={selectedInstitution}
                            handleInstitutionSearch={handleInstitutionSearch}
                            refreshers={refreshers}
                            regions={regions}
                            selectedRegion={selectedRegion}
                            handleRegionSearch={handleRegionSearch}
                            userData={userData}
                            logAnalyticsEvent={logAnalyticsEvent}
                        />
                    )}
                    {isSelected === 3 && (
                        <QuickFinderFilter
                            selectedProgram={selectedProgram}
                            handleProgramSearch={handleProgramSearch}
                            programs={programs}
                            selectedLevel={selectedLevel}
                            handleLevelsSearch={handleLevelsSearch}
                            checkExistingLevel={checkExistingLevel}
                            setExistingLevel={setExistingLevel}
                            logAnalyticsEvent={logAnalyticsEvent}
                        />
                    )}
                </div>
                <div className="mt-[10px] mb-[3px] px-[20px] py-[10px] font-robotoCondensed text-tinyText text-black">
                    {isSelected === 0 && "*Find the next available seat for your trade at an institution near you using our seat finder."}
                    {isSelected === 1 && "*Find the next available seat for your trade at an institution near you using our seat finder."}
                    {isSelected === 2 && "*Find the next available seat for your trade at an institution near you using our seat finder."}
                    {isSelected === 3 && "*Find the next available apprenticeship seat for your trade at an institution near you using our quick finder."}
                </div>
                {announcementsData.intakeUpdate.isVisible && isSelected != 2 && <div
                    className="mb-[3px] px-[20px] pb-[10px] font-robotoCondensed text-tinyText text-black">{announcementsData.intakeUpdate.message}</div>}
                <div className="border-t-2 border-gray">
                    <div className="ml-auto flex w-fit flex-row px-[22px] pt-[11px]">
                        <div className="px-[5px] font-robotoCondensed text-tinyText uppercase text-darkGray">Not Sure
                            Where to Start?
                        </div>
                        <div className="px-[5px] font-robotoCondensed text-tinyText uppercase text-blue">
                            <Link to="/tradeprograms">View All Trades</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FinderFilter;
