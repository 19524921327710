import { useStaticQuery, graphql } from 'gatsby';

const useRefresherQuery = () => {
    const { allCourseRefresher } = useStaticQuery(graphql`
        {
            allCourseRefresher(sort: { fields: name }) {
                nodes {
                    id
                    databaseId
                    institution
                    name
                    region
                    campus
                    url
                }
            }
        }
    `);

    return allCourseRefresher;
};

export default useRefresherQuery;
