export const sortByNameProperty = (arrToSort: any) => {

    if(!arrToSort || !arrToSort.length) return;

    // sort by name
    arrToSort.sort(function (a, b) {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;
    });
}