import React, {useEffect} from 'react';
import { Link } from 'gatsby';
import { sortByNameProperty } from '../../../helpers';

const InstitutionFilter = (props) => {
    const { selectedInstitution, handleInstitutionSearch, institutions, handleProgramSearch, selectedProgram, programs, selectedProgramType, handleProgramTypeSearch, allProgramTypes, logAnalyticsEvent } = props;

    const filteredResults = selectedInstitution + ' ' + selectedProgramType;

    const institution = institutions.nodes.find((i) => i.id === selectedInstitution);
    const institutionPrograms = institution?.publicPrograms;
    sortByNameProperty(institutionPrograms);
    const program = institution?.publicPrograms.find((p) => p.id === selectedProgram);
    const programTypes = program?.programTypeInstitution.filter((pt) => pt.institutions.includes(institution.databaseId)).map((item) => item.publicProgramTypeId);

    let programTypeSlug = '';
    //selectedProgramType.charAt(0).toUpperCase() + selectedProgramType.slice(1)}-Schedules

    if (selectedProgramType != 'ProgramType') {
        const programTypeItem = allProgramTypes.nodes.find((item) => item.name.toLowerCase() === selectedProgramType.toLowerCase());
        programTypeSlug = programTypeItem.slug;
    }

    useEffect(() => {
        if (selectedProgramType && selectedProgramType !== 'ProgramType') return;

        if (!selectedProgram) return;
        if (programTypes === undefined || !programTypes.length) return;

        if (programTypes.length === 1) {
            const event = {
                target: {
                    value: programTypes[0],
                },
            };

            handleProgramTypeSearch(event);
        }
    }, [selectedProgram, selectedProgramType, programTypes]);

    return (
        <>
            {selectedInstitution === 'Institution' ? (
                <select
                    value={selectedInstitution}
                    onChange={handleInstitutionSearch}
                    size={1}
                    className="mx-[11px] my-[5px] w-full rounded-[6px] border-2 border-blue py-[9px] pl-[11px] pr-[35px] font-robotoCondensed text-blue sm:w-[calc(100%/3)]"
                >
                    <option selected>Institution</option>
                    {institutions.nodes.map((institution, index) => {
                        return (
                            <option className="text-blue !font-robotoCondensed" key={index} value={institution.id}>
                                {institution.name}
                            </option>
                        );
                    })}
                </select>
            ) : (
                <select
                    size={1}
                    value={selectedInstitution}
                    onChange={handleInstitutionSearch}
                    className="mx-[11px] my-[5px] w-full rounded-[6px] border-2 border-blue py-[9px] pr-[35px] pl-[11px] font-robotoCondensed text-blue sm:w-[calc(100%/3)]"
                >
                    {institutions.nodes.map((institution, index) => {
                        return (
                            <option className="text-blue !font-robotoCondensed" key={index} value={institution.id}>
                                {institution.name}
                            </option>
                        );
                    })}
                </select>
            )}

            {selectedInstitution === 'Institution' ? (
                <select disabled size={1} className="mx-[11px] my-[5px] w-full rounded-[6px] border-2 border-gray py-[9px] pr-[35px] pl-[11px] font-robotoCondensed text-navPrimary text-gray sm:w-[calc(100%/3)]">
                    <option selected>Program</option>
                </select>
            ) : (
                <select
                    className="mx-[11px] my-[5px] w-full rounded-[6px] border-2 border-blue py-[9px] pl-[11px] pr-[35px] font-robotoCondensed text-navPrimary text-blue sm:w-[calc(100%/3)]"
                    onChange={handleProgramSearch}
                    value={selectedProgram}
                    size={1}
                >
                    {selectedProgram === 'Program' && <option selected>Program</option>}

                    {institutionPrograms?.map((program, index) => {
                        return (
                            <option className="text-blue !font-robotoCondensed" key={index} value={program.id}>
                                {program.name}
                            </option>
                        );
                    })}
                </select>
            )}

            {selectedProgram === 'Program' ? (
                <select
                    disabled
                    size={1}
                    onChange={handleProgramTypeSearch}
                    className="mx-[11px] my-[5px] w-full rounded-[6px] border-2 border-gray py-[9px] pl-[11px] pr-[35px]  font-robotoCondensed text-navPrimary text-gray sm:w-[calc(100%/3)]"
                >
                    <option selected>Program Type</option>
                </select>
            ) : (
                <select
                    value={selectedProgramType}
                    size={1}
                    onChange={handleProgramTypeSearch}
                    className="mx-[11px] my-[5px] w-full rounded-[6px] border-2 border-blue py-[9px] pr-[35px] pl-[11px]  font-robotoCondensed text-navPrimary text-blue sm:w-[calc(100%/3)]"
                >
                    {selectedProgramType === 'ProgramType' && <option selected>Program Type</option>}
                    {allProgramTypes.nodes.map((type, index) => {
                        return (
                            programTypes?.includes(type.name.toLowerCase()) && (
                                <option className="text-blue !font-robotoCondensed" key={index} value={type.name}>
                                    {type.name}

                                    {/* {type && type.charAt(0).toUpperCase() + type.slice(1)} */}
                                </option>
                            )
                        );
                    })}
                </select>
            )}

            {selectedProgram != 'Program' && selectedProgramType != 'ProgramType' && selectedInstitution != 'Institution' && programTypeSlug != '' ? (
                <Link
                    to={selectedProgramType ? `${program?.uri}/${programTypeSlug}/${institution.slug.toUpperCase()}` : programs.nodes[selectedProgram]?.uri}
                    state={{ filteredResults }}
                    onClick={() => logAnalyticsEvent('institution_filter', 'institution_selection_values', `${institution.name}-${program.name}-${selectedProgramType}`)}
                    className=" radius-[6px] mx-[11px] my-[5px] whitespace-nowrap rounded-[6px] border-2 border-blue px-[26px] py-[11.5px] font-robotoCondensed text-navPrimary uppercase text-blue "
                >
                    Find my Seat!
                </Link>
            ) : (
                <button className="radius-[6px] mx-[11px] my-[5px] cursor-default whitespace-nowrap rounded-[6px] border-2 border-gray px-[26px] py-[11.5px] font-robotoCondensed text-navPrimary uppercase text-gray ">
                    Find my Seat!
                </button>
            )}
        </>
    );
};

export default InstitutionFilter;
