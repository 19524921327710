import { useStaticQuery, graphql } from 'gatsby';

const useProgramTypeQuery = () => {
    const { allPublicProgramType } = useStaticQuery(graphql`
        {
            allPublicProgramType {
                nodes {
                    name
                    slug
                    id
                    databaseId
                }
            }
        }
    `);

    return allPublicProgramType;
};

export default useProgramTypeQuery;
