import React, { useEffect } from "react";
import { Link } from "gatsby";
import { getClosestLocation } from "../../../helpers/user";

const ProgramFilter = (props) => {
    const { selectedProgram, handleProgramSearch, selectedProgramType, handleProgramTypeSearch, selectedRegion, handleRegionSearch, programs, regions, allProgramTypes, userData, logAnalyticsEvent } = props;

    const filteredResults = selectedRegion + " " + selectedProgramType.toLowerCase();

    const program = programs?.nodes[selectedProgram];
    const programTypeRegions = program?.programTypeRegion.find((r) => r.publicProgramTypeId === selectedProgramType.toLowerCase());
    const programTypes = allProgramTypes.nodes.filter((item) => programs?.nodes[selectedProgram]?.publicProgramTypeIds.includes(item.name.toLowerCase()));
    const programRegions = regions.nodes.filter((item) => programTypeRegions?.regions.includes(item.databaseId));
    const region = programRegions?.find((r) => r.name === selectedRegion);

    let programTypeSlug = "";
    //${selectedProgramType.charAt(0).toUpperCase() + selectedProgramType.slice(1)}-Schedules

    if (selectedProgramType != "ProgramType") {
        const programTypeItem = allProgramTypes.nodes.find((item) => item.name.toLowerCase() === selectedProgramType.toLowerCase());
        programTypeSlug = programTypeItem.slug;
    }

    useEffect(() => {
        if (selectedRegion && selectedRegion !== "Region") return;
        if (!programRegions.length) return;

        const locations = [];
        programRegions.map((item) => locations.push(...item.locations));
        const closestLocation = getClosestLocation(locations, userData);

        const region = programRegions.find((item) => item.locations.find((l) => l.latitude === closestLocation.latitude && l.longitude === closestLocation.longitude));

        const event = {
            target: {
                value: region?.name,
            },
        };

        handleRegionSearch(event);
    }, [selectedProgramType, selectedRegion, programRegions]);

    useEffect(() => {
        if (selectedProgramType && selectedProgramType !== "ProgramType") return;

        if (!selectedProgram) return;
        if (!programTypes.length) return;

        const event = {
            target: {
                value: programTypes[0]?.name,
            },
        };

        handleProgramTypeSearch(event);
    }, [selectedProgram, selectedProgramType, programTypes]);

    return (
        <>
            {selectedProgram === "Program" ? (
                <select
                    onChange={handleProgramSearch}
                    value={selectedProgram}
                    className="mx-[11px] my-[5px] w-full rounded-[6px] border-2 border-blue  py-[9px] px-[11px] font-robotoCondensed text-blue  sm:w-[calc(100%/3)]"
                >
                    <option selected>Program</option>
                    {programs.nodes.map((program, programIndex) => {
                        return (
                            <option className="font-robotoCondensed text-blue" key={programIndex} value={programIndex}>
                                {program.name}
                            </option>
                        );
                    })}
                </select>
            ) : (
                <select
                    onChange={handleProgramSearch}
                    value={selectedProgram}
                    className="mx-[11px] my-[5px] w-full rounded-[6px] border-2 border-blue py-[9px] pl-[11px] pr-[35px] font-robotoCondensed text-blue sm:w-[calc(100%/3)]"
                >
                    {programs.nodes.map((program, programIndex) => {
                        return (
                            <option className="font-robotoCondensed text-blue" key={programIndex} value={programIndex}>
                                {program.name}
                            </option>
                        );
                    })}
                </select>
            )}

            {selectedProgram === "Program" ? (
                <select disabled size={1} className="mx-[11px] my-[5px] w-full rounded-[6px] border-2 border-gray py-[9px] px-[11px] font-robotoCondensed text-navPrimary text-gray sm:w-[calc(100%/3)]">
                    <option selected>Program Type</option>
                </select>
            ) : (
                <select
                    value={selectedProgramType}
                    onChange={handleProgramTypeSearch}
                    className="mx-[11px] my-[5px] w-full rounded-[6px] border-2 border-blue py-[9px] px-[11px] pr-[35px] font-robotoCondensed text-navPrimary text-blue sm:w-[calc(100%/3)]"
                >
                    {selectedProgramType === "ProgramType" && <option selected>Program Type</option>}

                    {programTypes &&
                        programTypes?.map((type, index) => {
                            return (
                                <option className="font-robotoCondensed text-blue" key={index} value={type.name}>
                                    {type.name}
                                </option>
                            );
                        })}

                    {/*{programType.nodes.map((type, index) => {*/}
                    {/*    return (*/}
                    {/*        programs?.nodes[selectedProgram]?.publicProgramTypeIds.includes(type.name.toLowerCase()) && (*/}
                    {/*            <option className="text-blue" key={index} value={type.name}>*/}
                    {/*                {type.name}*/}

                    {/*                /!* {type && type.charAt(0).toUpperCase() + type.slice(1)} *!/*/}
                    {/*            </option>*/}
                    {/*        )*/}
                    {/*    );*/}
                    {/*})}*/}
                </select>
            )}

            {selectedProgram === "Program" ? (
                <select disabled size={1} className="mx-[11px] my-[5px] w-full rounded-[6px] border-2 border-gray py-[9px] px-[11px] font-robotoCondensed text-navPrimary text-gray sm:w-[calc(100%/3)]">
                    <option selected>Region</option>
                </select>
            ) : (
                <select
                    value={selectedRegion}
                    onChange={handleRegionSearch}
                    size={1}
                    className="mx-[11px] my-[5px] w-full rounded-[6px] border-2 border-blue py-[9px] px-[11px] pr-[35px] font-robotoCondensed text-navPrimary text-blue sm:w-[calc(100%/3)]"
                >
                    {selectedRegion === "Region" && <option selected>Region</option>}

                    {programRegions?.map((region, index) => {
                        return (
                            <option className="font-robotoCondensed text-blue" key={index} value={region.name}>
                                {region.name}
                            </option>
                        );
                    })}

                    {/*{regions.nodes.map((region, index) => {*/}
                    {/*    return (*/}
                    {/*        programs?.nodes[selectedProgram]?.regions.includes(region.databaseId) && (*/}
                    {/*            <option className="text-blue" key={index} value={region.id}>*/}
                    {/*                {region.name}*/}
                    {/*            </option>*/}
                    {/*        )*/}
                    {/*    );*/}
                    {/*})}*/}
                </select>
            )}

            {selectedProgram != "Program" && selectedProgramType != "ProgramType" && selectedRegion != "Region" && programTypeSlug != "" ? (
                <Link
                    to={selectedProgramType ? `${programs.nodes[selectedProgram]?.uri}/${programTypeSlug}` : programs.nodes[selectedProgram]?.uri}
                    state={{ filteredResults, selectedRegion: region }}
                    onClick={() => logAnalyticsEvent("program_filter", "program_selection_values", `${programs.nodes[selectedProgram]?.name}-${selectedProgramType}-${selectedRegion}`)}
                    className=" radius-[6px] mx-[11px] my-[5px] whitespace-nowrap rounded-[6px] border-2 border-blue px-[26px] py-[11.5px] font-robotoCondensed text-navPrimary uppercase text-blue "
                >
                    Find my Seat!
                </Link>
            ) : (
                <button className="radius-[6px] mx-[11px] my-[5px] cursor-default whitespace-nowrap rounded-[6px] border-2 border-gray px-[26px] py-[11.5px] font-robotoCondensed text-navPrimary uppercase text-gray ">
                    Find my Seat!
                </button>
            )}
        </>
    );
};

export default ProgramFilter;
