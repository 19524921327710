import React, { useState } from "react";
import FinderFilter from "./FinderFilter";

const SeatFinder = (props) => {
    const [isSelected, setIsSelected] = useState(0);
    const seatFinderModule = {
        seatFinder: [
            {
                title: "Program",
                searchSubHeader: "*Find the next available apprenticeship seat for your trade at an institution near you using our quick finder.",
            },
            {
                title: "Institution",
                searchSubHeader: "*Find the next available apprenticeship seat for your trade at an institution near you using our quick finder.",
            },
            {
                title: "Course Refresher",
                searchSubHeader: "*Find the next available course refresher seat for your trade at an institution near you using our quick finder.",
            },
            {
                title: "Quick Finder",
                searchSubHeader: "*Find the next available apprenticeship seat for your trade at an institution near you using our quick finder.",
            },
        ],
        header: "Bc Trades Training Seat Finder",
        buttonHeader: "Find a Seat by:",
    };

    return (
        <div
            style={{
                background: "linear-gradient(96.45deg, rgba(0, 0, 0, 0.7) 30.4%, rgba(0, 0, 0, 0) 71.63%)",
            }}
            className="z-1 relative h-[100%] pb-[90px] pt-[60px] md:pt-[114px] "
        >
            <div className="container mx-auto flex justify-center">
                <div className="mx-[20px] flex flex-col">
                    <div
                        className="mx-[20px] font-robotoCondensed text-seatFinderHeader font-bold uppercase text-white md:mx-[0px]">{seatFinderModule.header}</div>
                    <div
                        className="mx-[20px] mt-[25px] mb-[21px] font-robotoCondensed text-finderHeaderText uppercase text-white md:mx-[0px]">{seatFinderModule.buttonHeader}</div>
                    <div
                        className="mx-auto mb-[35px] flex w-full w-full flex-col rounded-[30px] border bg-black py-[4px] px-[6px] sm:mx-[0px] sm:w-fit sm:flex-row">
                        {seatFinderModule.seatFinder.map((seat, index) => {
                            return (
                                <button
                                    onClick={() => setIsSelected(index)}
                                    key={index}
                                    className={
                                        isSelected === index
                                            ? "whitespace-nowrap rounded-[30px] bg-blue py-[11px] px-[23px] font-robotoCondensed text-finderHeaderText uppercase text-white"
                                            : "whitespace-nowrap py-[11px] px-[23px]  font-robotoCondensed text-finderHeaderText uppercase text-white"
                                    }
                                >
                                    {seat.title}
                                </button>
                            );
                        })}
                    </div>
                    <FinderFilter isSelected={isSelected} setIsSelected={setIsSelected} />
                </div>
            </div>
        </div>
    );
};

export default SeatFinder;
