import { useStaticQuery, graphql } from 'gatsby';

const useProgramQuery = () => {
    const { allPublicProgram } = useStaticQuery(graphql`
        {
            allPublicProgram(sort: { fields: name }) {
                nodes {
                    uri
                    name
                    id
                    slug
                    hasApprenticeSchedule
                    hasFoundationSchedule
                    hasVocationalSchedule
                    hasYouthSchedule
                    isChallengeExam
                    sector {
                        slug
                    }
                    schedules {
                        institution {
                            name
                        }
                        apprenticeLevel
                    }

                    regions
                    publicProgramTypeIds
                    programTypeRegion {
                        publicProgramTypeId
                        regions
                    }
                }
            }
        }
    `);

    return allPublicProgram;
};

export default useProgramQuery;
