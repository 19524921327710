import * as Sentry from "@sentry/gatsby";

export const DEFAULT_REGION = { lat: 49.2733, long: -123.1025 };

export const getUserLatLong = async () => {
    try {
        const response = await fetch("https://ipapi.co/json/");

        if (!response.ok) {
            throw new Error(`ipapi.co network error with status ${response.status}`);
        }

        const data = await response.json();

        return {
            lat: data.latitude,
            long: data.longitude,
        };
    } catch (error) {
        if (error instanceof TypeError) {
            Sentry.captureMessage("Fetch call to ipapi threw TypeError");
        } else {
            throw error;
        }
    }
};

export const getClosestLocation = (locations, userLatLong) => {
    if (!userLatLong) {
        userLatLong = DEFAULT_REGION;
    }

    let curr = locations[0];
    let diff = Math.abs(userLatLong.lat - curr.latitude) + Math.abs(userLatLong.long - curr.longitude) / 2;

    for (let i = 1; i <= locations.length - 1; ++i) {
        let newDiff = Math.abs(userLatLong.lat - locations[i].latitude) + Math.abs(userLatLong.long - locations[i].longitude) / 2;

        if (newDiff < diff) {
            diff = newDiff;
            curr = locations[i];
        }
    }

    return curr;
};
