import { useStaticQuery, graphql } from 'gatsby';

const useInstitutionQuery = () => {
    const { allInstitution } = useStaticQuery(graphql`
        {
            allInstitution(sort: { fields: name }) {
                nodes {
                    id
                    databaseId
                    slug
                    name
                    shortName
                    contact {
                        address
                        director
                        institutionId
                        jobTitle
                        name
                    }
                    international {
                        internationalUrl
                    }
                    publicPrograms {
                        id
                        uri
                        databaseId
                        name
                        programTypeInstitution {
                            publicProgramTypeId
                            institutions
                        }
                    }
                }
            }
        }
    `);

    return allInstitution;
};

export default useInstitutionQuery;
